import { Stack, ProgressIndicator, Text, Separator } from "@fluentui/react";
import CodeBlock from "../../components/CodeBlock";
import Navigation from "../../components/Navigation";
import { stackFullStyles, stackStyles, stackTokens } from "../../styles/stack";

const ownership = `struct Foo {
  x: i32,
}

fn main() {
  // Wir instanziieren Strukturen und binden sie an
  // Variablen, um Speicherressourcen zu erstellen
  let foo = Foo { x: 42 };
  // foo ist der Eigentümer
}`;

const scope = `struct Foo {
  x: i32,
}

fn main() {
  let foo_a = Foo { x: 42 };
  let foo_b = Foo { x: 13 };

  println!("{}", foo_a.x);

  println!("{}", foo_b.x);
  // foo_b wird hier fallen gelassen 
  // foo_a wird hier fallen gelassen 
}`;

const Structs: React.FunctionComponent = () => {
  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={0.0} />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Eigentümer</Text>
      </Stack>

      <Text variant="large">
        Die Instanziierung eines Typs und seine Bindung an einen Variablennamen
        erzeugt eine Speicherressource, die der Rust-Compiler während ihrer
        gesamten Lebensdauer validiert. Die gebundene Variable wird als
        Eigentümer der Ressource bezeichnet.
      </Text>

      <CodeBlock code={ownership} />

      <Separator />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Bereichsbasiertes Ressourcenmanagement</Text>
      </Stack>

      <Text variant="large">
        Rust verwendet das Ende des Geltungsbereichs als Ort, um eine Ressource
        zu dekonstruieren und freizugeben.
      </Text>

      <Text variant="large">
        Der Begriff für diese Dekonstruktion und Deallokation lautet{" "}
        <code className="inline">drop</code>.
      </Text>

      <Text variant="large">Details zum Speicher:</Text>

      <ul>
        <li>
          <Text variant="large">Rust hat keinen Garbage Collector.</Text>
        </li>
        <li>
          <Text variant="large">
            Dies wird in C++ auch Resource Acquisition Is Initialization ( RAII
            ) genannt.
          </Text>
        </li>
      </ul>

      <CodeBlock code={scope} />

      <Navigation prev="/structs/test" next="/ownership/move" />
    </Stack>
  );
};

export default Structs;
