import { Stack, ProgressIndicator, Text, Separator } from "@fluentui/react";
import CodeBlock from "../../components/CodeBlock";
import Navigation from "../../components/Navigation";
import { stackFullStyles, stackStyles, stackTokens } from "../../styles/stack";

const borrow = `struct Foo {
    x: i32,
}

fn main() {
    let foo = Foo { x: 42 };
    let f = &foo;
    println!("{}", f.x);
    // f wird hier fallen gelassen 
    // foo wird hier fallen gelassen 
}`;

const deref = `fn main() {
    let mut foo = 42;
    let f = &mut foo;
    let bar = *f; // Erhalte eine Kopie des Wertes des Besitzers
    *f = 13;      // Setzt den Wert des Besitzers über die Referenz
    println!("{}", bar);
    println!("{}", foo);
}`;

const Structs: React.FunctionComponent = () => {
  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={0.66} />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Ausleihen von Referenzen</Text>
      </Stack>

      <Text variant="large">
        Referenzen ermöglichen uns den Zugriff auf eine Ressource mit dem
        Operator <code className="inline">{"&"}</code>.
      </Text>

      <Text variant="large">
        Referenzen werden ebenso wie andere Ressourcen freigegeben.
      </Text>

      <CodeBlock code={borrow} />

      <Separator />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Ausleihe von veränderlichem Referenzen</Text>
      </Stack>

      <Text variant="large">
        Mit dem Operator <code className="inline">{"&mut"}</code> können auch
        veränderbaren Zugriff auf eine Ressource leihen.
      </Text>

      <Text variant="large">
        Ein Ressourceneigentümer kann nicht verschoben oder verändert werden,
        solange er veränderlich ausgeliehen ist.
      </Text>

      <Separator />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Dereferenzierung</Text>
      </Stack>

      <Text variant="large">
        Mit <code className="inline">{"&mut"}</code>
        -Referenzen kann der Wert des Besitzers mit dem Operator{" "}
        <code className="inline">{"*"}</code> festlegt werden.
      </Text>

      <Text variant="large">
        Mit dem Operator <code className="inline">{"*"}</code> kann eine Kopie
        eines Wertes erhalten, der uns gehört (wenn der Wert kopiert werden
        kann).
      </Text>

      <CodeBlock code={deref} />

      <Navigation prev="/ownership/move" next="/ownership/test" />
    </Stack>
  );
};

export default Structs;
