import * as React from "react";
import { Stack, ProgressIndicator } from "@fluentui/react";

import MaskedQuestion from "../../components/MaskedQuestion";
import SingleChoice from "../../components/SingleChoice";
import Navigation from "../../components/Navigation";
import CodeBlock from "../../components/CodeBlock";

import { stackFullStyles, stackTokens } from "../../styles/stack";

const match = `fn main() {
    let x = 42;

    match x {
        0 => {
          println!("0");
        }
        1..=9 => {
          println!("19");
        }
        gefundene_zahl @ 10..42 => {
          println!("xx");
        }
        _ => {
          println!("unbekannt");
        }
    }
}`;

const Test: React.FunctionComponent = () => {
  const [testCompleted, setTestCompleted] = React.useState(false);

  const [test1, setTest1] = React.useState(false);
  const [test2, setTest2] = React.useState(false);
  const [test3, setTest3] = React.useState(false);

  React.useEffect(() => {
    if (test1 && test2 && test3) setTestCompleted(true);
  }, [test1, test2, test3]);

  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={1} />
      <Stack horizontalAlign="center" tokens={stackTokens}>
        <Stack horizontalAlign="center" tokens={stackTokens}>
          <br />
          <br />

          <MaskedQuestion
            question="Mit hilfe welches Schlüsselwortes kann eine Schleife unterbrochen werden?"
            answer="break"
            mask="*****"
            maskChar="_"
            errorMessage="Hier stimmt etwas noch nicht"
            setTestCompleted={setTest1}
          />
          <MaskedQuestion
            question="Welches Schlüsselwort ist equivaletn zu 'switch' in C?"
            answer="match"
            mask="*****"
            maskChar="_"
            errorMessage="Hier stimmt etwas noch nicht"
            setTestCompleted={setTest2}
          />

          <CodeBlock code={match} />

          <SingleChoice
            question={"Was ist die Ausgabe des obrigen Programms?"}
            answerKey={"D"}
            options={[
              {
                key: "A",
                text: "0",
              },
              {
                key: "B",
                text: "19",
              },
              {
                key: "C",
                text: "xx",
              },
              {
                key: "D",
                text: "unbekannt",
              },
            ]}
            setTestCompleted={setTest3}
          />
        </Stack>
      </Stack>

      <Navigation
        prev="/flow/match"
        next={testCompleted ? "/structs/struct" : ""}
      />
    </Stack>
  );
};

export default Test;
