import { Separator, Stack, Text } from "@fluentui/react";
import Link from "../components/Link";
import Navigation from "../components/Navigation";
import { stackStyles, stackTokens } from "../styles/stack";
import { boldStyle } from "../styles/text";

const Variables: React.FunctionComponent = () => {
  return (
    <Stack
      horizontalAlign="center"
      verticalFill
      styles={stackStyles}
      tokens={stackTokens}
    >
      <Stack
        horizontalAlign="center"
        tokens={stackTokens}
        styles={{
          root: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "0 2.5rem",
            alignItems: "end",
          },
        }}
      >
        <Link href="/impressum">Impressum </Link>
        <Text variant="xxLarge" styles={boldStyle}>
          Einleitung
        </Text>
        <Link href="/structure">Inhaltsverzeichnis </Link>
      </Stack>

      <Separator styles={{ root: { width: "100%" } }} />

      <Text variant="xLarge">
        An wen richtet sich dieses E-Learning System?
      </Text>

      <Text variant="large">
        Dieses E-Learning System richtet sich an Schülerinnen und Schüler, die
        sich für die Entwicklung von Software interessieren.
      </Text>

      <Text variant="large">
        Außerdem richtet sich das E-Learning System an Personen, die sich mit
        der Programmierung schon einmal beschäftigt haben, aber sich noch weiter
        bewegen möchten.
      </Text>

      <br />

      <Text variant="xLarge">
        Inhaltlich ist dieses E-Learning System nicht komplett.
      </Text>

      <Text variant="large">
        Aufgrund der großen Anzahl an Inhalten, die es zu erwähnen gibt, ist es
        nicht möglich, alle Inhalte ausführlich zu behandeln, da dieses
        E-Learning System nur einen Ausblick über den Einstieg in die
        Programmierung in Rust geben soll.
      </Text>

      <br />

      <Text variant="xLarge">Der Fokus</Text>

      <Text variant="large">
        Bei der Erstellung dieses E-Learning Systems wurde der Fokus auf das
        Zusammenführen von Inhalten und der Erstellung eines einheitlichen und
        kompakten E-Learning Systems gesetzt und weniger auf die Vollständigkeit
        der Inhalte.
      </Text>

      <br />

      <Text variant="xLarge">Anleitung</Text>

      <Text variant="large">
        Um ein besseres Erlebnis zu erhalten, wird empfohlen, die{" "}
        <code className="inline">F11</code>-Taste zu drücken, um das E-Learning
        System im Vollbildmodus zu starten.
      </Text>

      <br />

      <Text variant="xLarge">Tests</Text>

      <Text variant="large">
        Die letzte Folie jedes Kapitels enthält einen Test, der die Inhalte des
        Kapitels testet. Nur wenn man den Test bestanden hat, wird das nächste
        Kapitel freigeschaltet.
      </Text>
      <Navigation prev="/structure" next="/basics/variables" />
    </Stack>
  );
};

export default Variables;
