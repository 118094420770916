import { Stack, ProgressIndicator } from "@fluentui/react";
import * as React from "react";
import MaskedQuestion from "../../components/MaskedQuestion";
import Navigation from "../../components/Navigation";
import SingleChoice from "../../components/SingleChoice";
import { stackFullStyles, stackTokens } from "../../styles/stack";

const TextFieldMaskedExample: React.FunctionComponent = () => {
  const [testCompleted, setTestCompleted] = React.useState(false);

  const [test1, setTest1] = React.useState(false);
  const [test2, setTest2] = React.useState(false);
  const [test3, setTest3] = React.useState(false);

  React.useEffect(() => {
    if (test1 && test2 && test3) setTestCompleted(true);
  }, [test1, test2, test3]);

  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={1} />
      <Stack horizontalAlign="center" tokens={stackTokens}>
        <Stack horizontalAlign="center" tokens={stackTokens}>
          <br />
          <br />

          <MaskedQuestion
            question="Mit hilfe welches Schlüsselwortes kann eine Struktur erzeugt werden?"
            answer="struct"
            mask="******"
            maskChar="_"
            errorMessage="Hier stimmt etwas noch nicht"
            setTestCompleted={setTest1}
          />

          <SingleChoice
            question={
              "In welchem Speicher werden Variablen, die innerhalb einer Funktion deklariert wurden, gespeichert?"
            }
            answerKey={"B"}
            options={[
              {
                key: "A",
                text: "data memory",
              },
              {
                key: "B",
                text: "stack memory",
              },
              {
                key: "C",
                text: "heap memory",
              },
            ]}
            setTestCompleted={setTest2}
          />

          <SingleChoice
            question={
              "Auf welche Art von Methoden trifft folgende Aussage zu?\n'Methode, die zum Datentyp selber assoziiert wird.'"
            }
            answerKey={"A"}
            options={[
              {
                key: "A",
                text: "statische",
              },
              {
                key: "B",
                text: "instanz",
              },
            ]}
            setTestCompleted={setTest3}
          />
        </Stack>
      </Stack>

      <Navigation
        prev="/structs/struct-init"
        next={testCompleted ? "/ownership/owner" : ""}
      />
    </Stack>
  );
};

export default TextFieldMaskedExample;
