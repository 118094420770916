import { IStackStyles, Stack, Text } from "@fluentui/react";
import { stackTokens } from "../styles/stack";
import { boldStyle } from "../styles/text";
import Link from "./Link";

const customStackStyles: Partial<IStackStyles> = {
  root: {
    position: "absolute",
    display: "flex",
    left: 0,
    right: 0,
    bottom: 0,
    padding: "1rem",
    width: "100%",
    justifyContent: "space-between",
  },
};

interface NavigationProps {
  prev?: string;
  next?: string;
}

const Navigation: React.FunctionComponent<NavigationProps> = ({
  prev,
  next,
}) => {
  return (
    <Stack
      horizontal
      tokens={stackTokens}
      horizontalAlign="center"
      styles={customStackStyles}
    >
      {prev && (
        <Link href={prev} type="button">
          <Text variant="medium" styles={boldStyle}>
            Vorherige Seite
          </Text>
        </Link>
      )}

      {next && (
        <Link
          href={next}
          type="button"
          style={{
            marginLeft: "auto",
          }}
        >
          <Text variant="medium" styles={boldStyle}>
            Nächste Seite
          </Text>
        </Link>
      )}
    </Stack>
  );
};

export default Navigation;
