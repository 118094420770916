import { Stack, ProgressIndicator, Text } from "@fluentui/react";
import CodeBlock from "../../components/CodeBlock";
import Navigation from "../../components/Navigation";
import { stackFullStyles, stackStyles, stackTokens } from "../../styles/stack";

const struct_init = `fn main() {
    let buch = Product {
        name: String::from("Buch"),
        price: 10.0,
        description: String::from("Ein Buch über die Welt der Programmierung"),
    };

    let kaffee = Product {
        name: String::from("Kaffee"),
        price: 2.0,
        description: String::from("Ein Kaffee mit Milch"),
    };

    println!("{}", buch.name);
    println!("{}", kaffee.name);
}`;

const Memory: React.FunctionComponent = () => {
  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={0.66} />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Structs initialisieren</Text>
      </Stack>

      <Text variant="large">
        Wenn ein struct <code className="inline">instanziiert</code> wird, legt
        das Programm die Felder des structs Seite an Seite im Speicher an.
      </Text>

      <Text variant="large">
        Die Initialisierung erfolgt mit folgendem Syntax:
      </Text>

      <Text variant="large">
        <code className="inline">{"StructName { feld1, feld2, ... }"}</code>
      </Text>

      <Text variant="large">
        Auf die Felder des structs werden mit einem{" "}
        <code className="inline">.</code> zugegriffen.
      </Text>

      <Text variant="large">In unserem Beispiel bedeutet das:</Text>

      <ul className="space-y-1">
        <li>
          <Text variant="large">
            Text innerhalb der Anführungszeichen sind read-only Daten (z.B.
            "Buch"), daher sind sie in{" "}
            <code className="inline">data memory</code>
          </Text>
        </li>
        <li>
          <Text variant="large">
            Der Funktionsaufruf <code className="inline">String::from</code>{" "}
            erstellt ein struct String das Seite an Seite der anderen Felder in{" "}
            <code className="inline">Product</code> auf den stack gelegt wird.
            Ein String repräsentiert (veränderbaren) Text, der…
          </Text>

          <ol>
            <li>
              <Text variant="medium">
                … im heap angelegt wird und daher dort verändert werden kann,
              </Text>
            </li>
            <li>
              <Text variant="medium">
                … die Adresse (Referenz) zum Speicherstück im heap im String
                struct speichert
              </Text>
            </li>
          </ol>
        </li>
      </ul>

      <CodeBlock code={struct_init} />

      <Navigation prev="/structs/memory" next="/structs/test" />
    </Stack>
  );
};

export default Memory;
