import { Stack, Text } from "@fluentui/react";
import Link from "../components/Link";

import { stackStyles, stackTokens } from "../styles/stack";
import { boldStyle } from "../styles/text";

const NoPage: React.FunctionComponent = () => {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      verticalFill
      styles={stackStyles}
      tokens={stackTokens}
    >
      <Text variant="xxLarge" styles={boldStyle}>
        Du hast das Ende der Lehrveranstaltung erreicht!
      </Text>

      <Stack horizontal tokens={stackTokens} horizontalAlign="center">
        <Link href="/">Hauptseite</Link>
      </Stack>
    </Stack>
  );
};

export default NoPage;
