import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Final from "./pages/Final";
import NoPage from "./pages/NoPage";
import Structure from "./pages/Structure";
import Introduction from "./pages/Introduction";
import Impressum from "./pages/Impressum";

import BasicsLayout from "./pages/basics/Layout";
import BasicsVariables from "./pages/basics/Variables";
import BasicsTypes from "./pages/basics/Types";
import BasicsFunctions from "./pages/basics/Functions";
import BasicTest from "./pages/basics/Test";

import FlowLayout from "./pages/flow/Layout";
import FlowIfElseLoop from "./pages/flow/IfElseLoop";
import FlowWhileFor from "./pages/flow/WhileFor";
import FlowMatch from "./pages/flow/Match";
import FlowTest from "./pages/flow/Test";

import StructsLayout from "./pages/structs/Layout";
import StructsStruct from "./pages/structs/Struct";
import StructsMemory from "./pages/structs/Memory";
import StructsStructInit from "./pages/structs/StructInit";
import StructsTest from "./pages/structs/Test";

import OwnershipLayout from "./pages/ownership/Layout";
import OwnershipOwner from "./pages/ownership/Owner";
import OwnershipMove from "./pages/ownership/Move";
import OwnershipBorrowing from "./pages/ownership/Borrowing";
import OwnershipTest from "./pages/ownership/Test";

import "highlight.js/styles/atom-one-light.css";
import "./index.css";

export const App: React.FunctionComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="structure" element={<Structure />} />
          <Route path="intro" element={<Introduction />} />
          <Route path="impressum" element={<Impressum />} />

          <Route path="basics" element={<BasicsLayout />}>
            <Route path="variables" element={<BasicsVariables />} />
            <Route path="data-types" element={<BasicsTypes />} />
            <Route path="functions" element={<BasicsFunctions />} />
            <Route path="test" element={<BasicTest />} />
          </Route>

          <Route path="flow" element={<FlowLayout />}>
            <Route path="if-else-loop" element={<FlowIfElseLoop />} />
            <Route path="while-for" element={<FlowWhileFor />} />
            <Route path="match" element={<FlowMatch />} />
            <Route path="test" element={<FlowTest />} />
          </Route>

          <Route path="structs" element={<StructsLayout />}>
            <Route path="struct" element={<StructsStruct />} />
            <Route path="memory" element={<StructsMemory />} />
            <Route path="struct-init" element={<StructsStructInit />} />
            <Route path="test" element={<StructsTest />} />
          </Route>

          <Route path="ownership" element={<OwnershipLayout />}>
            <Route path="owner" element={<OwnershipOwner />} />
            <Route path="move" element={<OwnershipMove />} />
            <Route path="borrow" element={<OwnershipBorrowing />} />
            <Route path="test" element={<OwnershipTest />} />
          </Route>

          <Route path="final" element={<Final />} />

          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
