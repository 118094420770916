import { IStackStyles, Stack } from "@fluentui/react";

import Lowlight from "react-lowlight";
import rust from "highlight.js/lib/languages/rust";

Lowlight.registerLanguage("rust", rust);

const styles: Partial<IStackStyles> = {
  root: { textAlign: "left", flexFlow: "row" },
};

const CodeBlock: React.FC<{
  code: string;
}> = ({ code }) => {
  return (
    <Stack styles={styles}>
      <Lowlight
        language="rust"
        value={code}
        markers={[{ line: 1, className: "test" }]}
      />
    </Stack>
  );
};

export default CodeBlock;
