import { Stack, ProgressIndicator } from "@fluentui/react";
import * as React from "react";
import MaskedQuestion from "../../components/MaskedQuestion";
import Navigation from "../../components/Navigation";
import SingleChoice from "../../components/SingleChoice";
import { stackFullStyles, stackTokens } from "../../styles/stack";

const TextFieldMaskedExample: React.FunctionComponent = () => {
  const [testCompleted, setTestCompleted] = React.useState(false);

  const [test1, setTest1] = React.useState(false);
  const [test2, setTest2] = React.useState(false);
  const [test3, setTest3] = React.useState(false);

  React.useEffect(() => {
    if (test1 && test2 && test3) setTestCompleted(true);
  }, [test1, test2, test3]);

  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={1} />
      <Stack horizontalAlign="center" tokens={stackTokens}>
        <Stack horizontalAlign="center" tokens={stackTokens}>
          <br />
          <br />

          <MaskedQuestion
            question="Wie lautet der Begriff für das Fallenlassen von Ressourcen?"
            answer="drop"
            mask="****"
            maskChar="_"
            errorMessage="Hier stimmt etwas noch nicht"
            setTestCompleted={setTest1}
          />
          <MaskedQuestion
            question="Mit welchem Schlüsselwort leiht man sich eine veränderliche Referenz?"
            answer="&mut"
            mask="****"
            maskChar="_"
            errorMessage="Hier stimmt etwas noch nicht"
            setTestCompleted={setTest2}
          />

          <SingleChoice
            question={"Hat Rust einen Garbage Collector?"}
            answerKey={"B"}
            options={[
              {
                key: "A",
                text: "ja",
              },
              {
                key: "B",
                text: "nein",
              },
            ]}
            setTestCompleted={setTest3}
          />
        </Stack>
      </Stack>

      <Navigation
        prev="/ownership/borrow"
        next={testCompleted ? "/final" : ""}
      />
    </Stack>
  );
};

export default TextFieldMaskedExample;
