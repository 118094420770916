import { ProgressIndicator, Stack, Text } from "@fluentui/react";
import CodeBlock from "../../components/CodeBlock";
import Navigation from "../../components/Navigation";
import { stackFullStyles, stackStyles, stackTokens } from "../../styles/stack";

const variables1 = `fn main() {
  let x = 5;
  println!("Der Wert von x ist: {x}");
}`;

const variables2 = `fn main() {
  let x = 5;
  println!("Der Wert von x ist: {x}");

  let x = 5;
  println!("Der Wert von x ist: {x}");
}`;

const mutable = `fn main() {
  let mut x = 5;
  println!("Der Wert von x ist: {x}");

  x = 5;
  println!("Der Wert von x ist: {x}");
}`;

const Variables: React.FunctionComponent = () => {
  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={0.0} />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Variablen</Text>
      </Stack>

      <Text variant="large">
        Variablen werden mit dem Schlüsselwort{" "}
        <code className="inline">let</code> deklariert.
      </Text>

      <CodeBlock code={variables1} />

      <Text variant="large">
        Im Regelfall sollte man Rust die Entscheidung überlassen, um welchen
        Datentypen es sich handelt. In Sonderfällen kann der Typ explizit
        angegeben werden.
      </Text>

      <Text variant="large">
        Rust erlaubt sogenanntes{" "}
        <code className="inline">variable shadowing</code> (Variable
        überschatten). Bei Neudeklaration "verfällt" die vorige Deklaration und
        man kann mit der Variable arbeiten, als ob es sie davor noch nie gegeben
        hätte. Der Datentyp darf sich dabei auch ändern.
      </Text>

      <CodeBlock code={variables2} />

      <Text variant="large">
        Rust sorgt sich sehr darum, welche Variablen verändert werden können und
        welche nicht.
      </Text>

      <ul>
        <li>
          <Text variant="large">
            <code className="inline">mutable</code> (veränderlich) - der
            Speicherort der Variable kann beschrieben und gelesen werden
          </Text>
        </li>
        <li>
          <Text variant="large">
            <code className="inline">immutable</code> (unveränderlich) - der
            Compiler lässt nur das Lesen des Speicherortes zu
          </Text>
        </li>
      </ul>

      <Text variant="large">
        Variablen, die verändert werden können, werden mit einem extra{" "}
        <code className="inline">mut</code> (Abkürzung für mutable)
        Schlüsselwort gekennzeichnet.
      </Text>

      <CodeBlock code={mutable} />

      <Navigation prev="/intro" next="/basics/data-types" />
    </Stack>
  );
};

export default Variables;
