import { ProgressIndicator, Stack, Text } from "@fluentui/react";
import CodeBlock from "../../components/CodeBlock";
import Navigation from "../../components/Navigation";
import { stackFullStyles, stackStyles, stackTokens } from "../../styles/stack";

const match = `fn main() {
    let x = 42;

    match x {
        0 => {
            println!("0");
        }
        // man kann auch vergleiche mit mehreren Werten machen
        1 | 2 => {
            println!("1 oder 2");
        }
        // man kann auch vergleiche mit Intervallen machen
        3..=9 => {
            println!("3 bis 9");
        }
        // man kann auch vergleiche machen und den Wert an eine Varaible binden
        gefundene_zahl @ 10..=100 => {
            println!("{}", x);
        }
        // und einen Wert, der nicht vorher beachtet wurde
        _ => {
            println!("unbekannt");
        }
    }
}`;

const Match: React.FunctionComponent = () => {
  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={0.66} />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Match</Text>
      </Stack>

      <Text variant="large">
        Was ist mit dem Switch Statement? Rust hat dazu auch eine Alternative.
      </Text>

      <Text variant="large">
        Mittels <code className="inline">{"match"}</code> können Werte mit allen
        möglichen Konditionen und Werten verglichen werden. Konditionen sind
        hier vielleicht das Interessante.
      </Text>

      <Text variant="large">
        <code className="inline">{"match"}</code> ist in Rust exhaustive
        (erschöpfend). Das bedeuted, dass jeder mögliche Wert getested werden
        muss, den die Variable annhemen kann. Man sollte daher sparsam mit ihnen
        umgehen.
      </Text>

      <CodeBlock code={match} />

      <Navigation prev="/flow/while-for" next="/flow/test" />
    </Stack>
  );
};

export default Match;
