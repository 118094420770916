import { Stack, Text } from "@fluentui/react";

import { stackStyles, stackTokens } from "../styles/stack";
import { boldStyle } from "../styles/text";

const NoPage: React.FunctionComponent = () => {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      verticalFill
      styles={stackStyles}
      tokens={stackTokens}
    >
      <Text variant="xxLarge" styles={boldStyle}>
        Seite nicht gefunden, bitte kehre zurück.
      </Text>

      <Stack horizontal tokens={stackTokens} horizontalAlign="center"></Stack>
    </Stack>
  );
};

export default NoPage;
