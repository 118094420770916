import { ProgressIndicator, Stack, Text } from "@fluentui/react";
import CodeBlock from "../../components/CodeBlock";
import Navigation from "../../components/Navigation";
import { stackFullStyles, stackStyles, stackTokens } from "../../styles/stack";

const variables = `fn addieren(x: i32, y: i32) -> i32 {
  return x + y;
}

fn subtrahieren(x: i32, y: i32) -> i32 {
  x - y
}

fn main() {
  println!("42 + 13 = {}", addieren(42, 13));
  println!("42 - 13 = {}", subtrahieren(42, 13));
}`;

const Functions: React.FunctionComponent = () => {
  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={0.66} />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Funktionen</Text>
      </Stack>

      <Text variant="large">
        Eine Funktion kann eine beliebige (nicht-negative) Anzahl an Argumenten
        bzw. Parametern aufnehmen.
      </Text>

      <Text variant="large">
        Funktionsnamen sollten im <code className="inline">snake_case</code>{" "}
        Format vergeben werden.
      </Text>

      <Text variant="large">
        Hinweis: Wenn wir eine Funktion definieren, werden die Daten, die sie
        akzeptiert, Parameter genannt. Wenn wir die Funktion aufrufen und ihr
        Daten übergeben, nennt man sie Argumente.
      </Text>

      <CodeBlock code={variables} />
      <Navigation prev="/basics/data-types" next="/basics/test" />
    </Stack>
  );
};

export default Functions;
