import { Stack, Text } from "@fluentui/react";
import Link from "../components/Link";

import { stackStyles, stackTokens } from "../styles/stack";
import { boldStyle } from "../styles/text";

const Impressum: React.FunctionComponent = () => {
  return (
    <Stack
      horizontalAlign="center"
      verticalAlign="center"
      verticalFill
      styles={stackStyles}
      tokens={stackTokens}
    >
      <Text variant="xLarge" styles={boldStyle}>
        Ersteller
      </Text>
      <Text variant="large">Johannes Holzhauer</Text>
      <Text variant="large">holzhauer-johannes@gmx.de</Text>
      <br />
      <Text variant="xLarge">Betreuung</Text>
      <Text variant="large">Prof. Dr.-Ing. Karsten Hartmann</Text>
      <Text variant="large">karsten.hartmann@hs-merseburg.de</Text>
      <br />
      <Text variant="xLarge">Entstanden im Rahmen</Text>
      <Text variant="large">Seminararbeit für "E-Learning System"</Text>
      <br />
      <br />
      <Stack horizontal tokens={stackTokens} horizontalAlign="center">
        <Link href="/">Hauptseite</Link>
      </Stack>
    </Stack>
  );
};

export default Impressum;
