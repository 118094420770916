import { ProgressIndicator, Separator, Stack, Text } from "@fluentui/react";
import CodeBlock from "../../components/CodeBlock";
import Navigation from "../../components/Navigation";
import { stackFullStyles, stackStyles, stackTokens } from "../../styles/stack";

const while_loop = `fn main() {
    let mut x = 0;

    while x < 10 {
        x += 1;
    }

    println!("{}", x);
}`;

const for_loop = `fn main() {
    for x in 0..10 {
        println!("{}", x);
    }

    fox x in 0..=10 {
        println!("{}", x);
    }
}`;

const WhileFor: React.FunctionComponent = () => {
  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={0.33} />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">While</Text>
      </Stack>

      <Text variant="large">
        <code className="inline">{"while"}</code> Schleifen werden so lange
        ausgeführt, bis eine Bedingung nicht mehr erfüllt ist.
      </Text>

      <CodeBlock code={while_loop} />

      <Separator />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">For</Text>
      </Stack>

      <Text variant="large">
        In Rust sind <code className="inline">{"for"}</code>-Schleifen ein
        großes Upgrade. Wird ein Ausdruck (expression) in einem Iterator
        evaluiert, kann dieser in eine <code className="inline">{"for"}</code>
        -Schleife eingebaut werden.
      </Text>

      <Text variant="large">
        Was ist ein Iterator? Ein Iterator ist ein Objekt, dem die Frage "Was
        kommt als nächstes?" gestellt werden kann. Sind keine Elemente mehr
        vorhanden, wird die Schleife abgebrochen.
      </Text>

      <CodeBlock code={for_loop} />

      <Navigation prev="/flow/if-else-loop" next="/flow/match" />
    </Stack>
  );
};

export default WhileFor;
