import {
  FocusZone,
  FocusZoneDirection,
  List,
  Stack,
  Text,
} from "@fluentui/react";
import React from "react";
import Link from "../components/Link";
import Navigation from "../components/Navigation";
import { stackStyles, stackTokens } from "../styles/stack";
import { boldStyle } from "../styles/text";

interface Item {
  title: string;
  link?: string;
  children?: Item[];
}

const onRenderCell = (
  item: Item | undefined,
  index: number | undefined
): JSX.Element => {
  return (
    <li>
      {(item?.link && (
        <Link href={item?.link!}>
          <Text variant="large">{(index ?? 0) + 1} </Text>
          <Text variant="large" styles={boldStyle}>
            {item?.title}
          </Text>
        </Link>
      )) || (
        <>
          <Text variant="large">{(index ?? 0) + 1}. </Text>
          <Text variant="large" styles={boldStyle}>
            {item?.title}
          </Text>
        </>
      )}
      {item?.children && (
        <ul className="no-bullets">
          <List items={item?.children} onRenderCell={onRenderCell} />
        </ul>
      )}
    </li>
  );
};

const Structure: React.FunctionComponent = () => {
  const [items] = React.useState<Item[]>([
    {
      title: "Einleitung",
      link: "/intro",
    },
    {
      title: "Basics",
      children: [
        {
          title: "Variablen",
          link: "/basics/variables",
        },
        {
          title: "Daten Typen",
          link: "/basics/data-types",
        },
        {
          title: "Funktionen",
          link: "/basics/functions",
        },
        {
          title: "Test",
          link: "/basics/test",
        },
      ],
    },
    {
      title: "Kontrollfluss",
      children: [
        {
          title: "if/else & loop",
          link: "/flow/if-else-loop",
        },
        {
          title: "while & for",
          link: "/flow/while-for",
        },
        {
          title: "match",
          link: "/flow/match",
        },
        {
          title: "Test",
          link: "/flow/test",
        },
      ],
    },
    {
      title: "Datenstrukturen",
      children: [
        {
          title: "Datenstruktur",
          link: "/structs/struct",
        },
        {
          title: "Speicher",
          link: "/structs/memory",
        },
        {
          title: "Structs initialisieren",
          link: "/structs/struct-init",
        },
        {
          title: "Test",
          link: "/structs/test",
        },
      ],
    },
    {
      title: "Eigentümerschaften",
      children: [
        {
          title: "Eigentümer",
          link: "/ownership/owner",
        },
        {
          title: "Übertragung des Besitzes",
          link: "/ownership/move",
        },
        {
          title: "Ausleihen von Referenzen",
          link: "/ownership/borrow",
        },
        {
          title: "Test",
          link: "/ownership/test",
        },
      ],
    },
  ]);

  return (
    <Stack
      horizontalAlign="center"
      verticalFill
      styles={stackStyles}
      tokens={stackTokens}
    >
      <Stack
        horizontalAlign="center"
        tokens={stackTokens}
        styles={{
          root: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "0 2.5rem",
            alignItems: "end",
          },
        }}
      >
        <Link href="/impressum">Impressum </Link>
        <Text variant="xxLarge" styles={boldStyle}>
          Inhaltsverzeichnis
        </Text>
        <Link href="/"> </Link>
      </Stack>

      <br />
      <FocusZone direction={FocusZoneDirection.vertical}>
        <ul className="no-bullets" style={{ textAlign: "left" }}>
          <List items={items} onRenderCell={onRenderCell} />
        </ul>
      </FocusZone>
      <Navigation prev="/" next="/intro" />
    </Stack>
  );
};

export default Structure;
