import { Stack, ProgressIndicator, Text } from "@fluentui/react";
import CodeBlock from "../../components/CodeBlock";
import Navigation from "../../components/Navigation";
import { stackFullStyles, stackStyles, stackTokens } from "../../styles/stack";

const types = `fn main() {
  let t = true;

  let f: bool = false; // mit expliziter Typ-Annotation

  let x = 2.0; // f64

  let y: f32 = 3.0; // f32

  let tup = (500, 6.4, 1);

  let (x, y, z) = tup;

  println!("Der Wert von y ist: {y}");

  let a = [1, 2, 3, 4, 5];
}`;

const Types: React.FunctionComponent = () => {
  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={0.33} />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Datentypen</Text>
      </Stack>

      <Text variant="large">
        Rust besitzt eine Vielzahl an bekannten Datentypen
      </Text>

      <ul>
        <li>
          <Text variant="large">
            booleans - <code className="inline">bool</code> für true/false bzw.
            wahr/falsch Werte
          </Text>
        </li>

        <li>
          <Text variant="large">
            unsigned integers - <code className="inline">u8</code>{" "}
            <code className="inline">u32</code>{" "}
            <code className="inline">u64</code> für positive Ganzzahlen
          </Text>
        </li>

        <li>
          <Text variant="large">
            signed integers - <code className="inline">i8</code>{" "}
            <code className="inline">i32</code>{" "}
            <code className="inline">i64</code> für vorzeichenbehaftete
            Ganzzahlen
          </Text>
        </li>

        <li>
          <Text variant="large">
            pointer sized integers - <code className="inline">usize</code>{" "}
            <code className="inline">isize</code> für Indizes und Größen im
            Speicher Werte
          </Text>
        </li>

        <li>
          <Text variant="large">
            floating point - <code className="inline">f32</code>{" "}
            <code className="inline">f64</code> für Fließkommazahlen
          </Text>
        </li>

        <li>
          <Text variant="large">
            tuple - <code className="inline">(Wert, Wert, ...)</code> um
            festgelegte Sequenzen von Werten auf dem Stack zu speichern
          </Text>
        </li>

        <li>
          <Text variant="large">
            arrays - <code className="inline">[Wert, Wert, ...]</code> eine
            Kollektion von ähnlichen Elementen mit zur Compile-Zeit festgelegter
            Länge
          </Text>
        </li>

        <li>
          <Text variant="large">
            slices - eine Kollektion von ähnlichen Elementen mit Länge bekannt
            zur Laufzeit
          </Text>
        </li>

        <li>
          <Text variant="large">
            <code className="inline">str</code> (string slice) - Text mit zur
            Laufzeit bekannter Länge
          </Text>
        </li>
      </ul>

      <Text variant="large">Beispiele:</Text>

      <CodeBlock code={types} />

      <Navigation prev="/basics/variables" next="/basics/functions" />
    </Stack>
  );
};

export default Types;
