import * as React from "react";
import { Stack, ProgressIndicator } from "@fluentui/react";

import MaskedQuestion from "../../components/MaskedQuestion";
import SingleChoice from "../../components/SingleChoice";
import Navigation from "../../components/Navigation";

import { stackFullStyles, stackTokens } from "../../styles/stack";

const Test: React.FunctionComponent = () => {
  const [testCompleted, setTestCompleted] = React.useState(false);

  const [test1, setTest1] = React.useState(false);
  const [test2, setTest2] = React.useState(false);
  const [test3, setTest3] = React.useState(false);

  React.useEffect(() => {
    if (test1 && test2 && test3) setTestCompleted(true);
  }, [test1, test2, test3]);

  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={1} />
      <Stack horizontalAlign="center" tokens={stackTokens}>
        <Stack horizontalAlign="center" tokens={stackTokens}>
          <br />
          <br />

          <MaskedQuestion
            question="Wie sieht die Main-Funktion (meist) aus?"
            answer="fn main() { }"
            mask="** main() * *"
            maskChar="_"
            errorMessage="Hier stimmt etwas noch nicht"
            setTestCompleted={setTest1}
          />
          <MaskedQuestion
            question="Wie erzeugt man eine veränderbare Variable?"
            answer="let mut x: i32 = 0;"
            mask="let *** x: i32 * 0;"
            maskChar="_"
            errorMessage="Hier stimmt etwas noch nicht"
            setTestCompleted={setTest2}
          />
          <SingleChoice
            question={"Welche Namenskonvention  wird für Variablen verwendet?"}
            answerKey={"A"}
            options={[
              {
                key: "A",
                text: "Snake Case",
              },
              {
                key: "B",
                text: "Kebab Case",
              },
              {
                key: "C",
                text: "Camel Case",
              },
              {
                key: "D",
                text: "Pascal Case",
              },
            ]}
            setTestCompleted={setTest3}
          />
        </Stack>
      </Stack>

      <Navigation
        prev="/basics/functions"
        next={testCompleted ? "/flow/if-else-loop" : ""}
      />
    </Stack>
  );
};

export default Test;
