import { Outlet } from "react-router-dom";

import { Stack, Text } from "@fluentui/react";
import { stackStyles, stackTokens } from "../../styles/stack";
import { boldStyle } from "../../styles/text";
import Link from "../../components/Link";

const Layout: React.FunctionComponent = () => {
  return (
    <Stack horizontalAlign="center" verticalFill tokens={stackTokens}>
      <Stack horizontalAlign="center" tokens={stackTokens} styles={stackStyles}>
        <Stack
          horizontalAlign="center"
          tokens={stackTokens}
          styles={{
            root: {
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "0 2.5rem",
              alignItems: "end",
            },
          }}
        >
          <Link href="/impressum">Impressum </Link>
          <Text variant="xxLarge" styles={boldStyle}>
            E-Learning System
          </Text>
          <Link href="/structure">Inhaltsverzeichnis </Link>
        </Stack>
        <Outlet />
      </Stack>
    </Stack>
  );
};

export default Layout;
