import { ProgressIndicator, Separator, Stack, Text } from "@fluentui/react";
import CodeBlock from "../../components/CodeBlock";
import Navigation from "../../components/Navigation";
import { stackFullStyles, stackStyles, stackTokens } from "../../styles/stack";

const ifelse = `fn main() {
  let age = 17

  if age < 16 {
    println!("Keine alkoholische Getränke erlaubt");
  } else if age >= 16 && age < 18 {
    println!("Du darfst Bier, Wein und Sekt kaufen, aber keine Spirituosen");
  } else {
    println!("Du darfst alle Getränke kaufen");
  }
}`;

const loop = `fn main() {
    let mut x = 0;

    loop {
        x += 1;
        println!("{}", x);
        if x == 10 {
            break;
        }
    }

    println!("Loop beendet");
}`;

const IfElse: React.FunctionComponent = () => {
  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={0.0} />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">If/Else</Text>
      </Stack>

      <Text variant="large">Codeverzweigungen (branching) bietet viel.</Text>

      <Text variant="large">
        Außer der fehlenden Klammern ähnelt Rust sich den meisten
        Programmiersprachen. Wer braucht die schon? Logik war noch nie so
        sauber.
      </Text>

      <Text variant="large">
        Vergleichsoperatoren und logischen Operatoren werden hier meistens
        verwendet.
      </Text>

      <Text variant="large">
        Logische Operatoren sind: <code className="inline">==</code>,{" "}
        <code className="inline">!=</code>,{" "}
        <code className="inline">{"<"}</code>,{" "}
        <code className="inline">{">"}</code>,{" "}
        <code className="inline">{"<="}</code>,{" "}
        <code className="inline">{">="}</code>,{" "}
        <code className="inline">{"!"}</code>,{" "}
        <code className="inline">{"||"}</code>,{" "}
        <code className="inline">{"&&"}</code>.
      </Text>

      <CodeBlock code={ifelse} />

      <Separator />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Loop</Text>
      </Stack>

      <Text variant="large">
        Unendliche Schleifen gefällig? <code className="inline">{"loop"}</code>{" "}
        macht es möglich!
      </Text>

      <Text variant="large">
        Mit <code className="inline">{"break"}</code> kann die Schleife
        unterbrochen werden.
      </Text>

      <CodeBlock code={loop} />

      <Navigation prev="/basics/test" next="/flow/while-for" />
    </Stack>
  );
};

export default IfElse;
