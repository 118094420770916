import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import React from "react";

const SingleChoice: React.FunctionComponent<{
  question: string;
  answerKey: string;
  options: IChoiceGroupOption[];
  setTestCompleted: (testCompleted: boolean) => void;
}> = ({ question, answerKey, options, setTestCompleted }) => {
  const opt = options.map((o) => ({
    ...o,
    styles: { root: { fontSize: "1.1rem" } },
  }));

  const onChange = (
    ev?: React.FormEvent<HTMLInputElement | HTMLElement>,
    option?: IChoiceGroupOption
  ) => {
    if (option?.key === answerKey) setTestCompleted(true);
  };

  return (
    <ChoiceGroup
      options={opt}
      onChange={onChange}
      label={question}
      required={true}
      styles={{
        label: { fontSize: "1.25rem" },
        flexContainer: {
          maxWidth: "300px",
        },
      }}
    />
  );
};

export default SingleChoice;
