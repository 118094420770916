import { Stack, ProgressIndicator, Text } from "@fluentui/react";
import CodeBlock from "../../components/CodeBlock";
import Navigation from "../../components/Navigation";
import { stackFullStyles, stackStyles, stackTokens } from "../../styles/stack";

const types = `struct Product {
    name: String;
    price: f32;
    location: String;
    description: String;
    image: String;
    amount: u32;
}`;

const Memory: React.FunctionComponent = () => {
  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={0.33} />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Speicher</Text>
      </Stack>

      <Text variant="large">
        In Rust gibt es 3 unterschiedliche Speicher-Regionen:
      </Text>

      <ul className="space-y-1">
        <li>
          <Text variant="large">
            <code className="inline">data memory</code> (Datenspeicher) - Für
            Daten mit fester Größe und die statisch sind (zur Gesamtlebenszeit
            eines Programms verfügbar). Nehmen wir beispielsweise festen Text
            her (z.B. "Hallo Welt"). Die Bytes in diesem Schnipsel werden nur
            gelesen und sind bereits zur Kompilierzeit bekannt, daher kommen sie
            in den Datenspeicher. Compiler können dementsprechend den Code
            optimieren und sind in der Laufzeit am schnellsten "auszuwerten".
          </Text>
        </li>
        <li>
          <Text variant="large">
            <code className="inline">stack memory</code> (Stackspeicher) -
            Variablen, die innerhalb einer Funktion deklariert werden. Während
            eines Funktionsaufrufs ändert sich der Standort nicht. Wird eine
            weitere Funktion aufgerufen, werden die von der Unterfunktion
            benötigen Variablen weiter auf den Stack gelegt. Erreicht man das
            Ende der Funktion, werden die entsprechenden Variablen wieder vom
            Stack runtergenommen.
          </Text>
        </li>
        <li>
          <Text variant="large">
            <code className="inline">heap memory</code> (Heapspeicher) -
            Speicher, der zur Laufzeit für Daten reserviert wird. In dieser
            Region kann Speicher reserviert, bewegt, vergrößert, verkleinert und
            freigegeben werden. Dieses dynamische Speichermanagement kann etwas
            mehr Zeit in Anspruch nehmen. Beim Reservieren spricht man von
            memory allocationt (allozieren), beim Freigeben von memory
            deallocation.
          </Text>
        </li>
      </ul>

      <Navigation prev="/structs/struct" next="/structs/struct-init" />
    </Stack>
  );
};

export default Memory;
