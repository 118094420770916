import React from "react";
import { Stack, Text } from "@fluentui/react";

import { boldStyle } from "../styles/text";
import { stackStyles, stackTokens } from "../styles/stack";
import Navigation from "../components/Navigation";

const Home: React.FunctionComponent = () => {
  return (
    <Stack
      tokens={stackTokens}
      horizontalAlign="center"
      verticalAlign="center"
      verticalFill
      styles={stackStyles}
    >
      <Text variant="xxLarge" styles={boldStyle}>
        E-Learning System
      </Text>

      <br />

      <Text variant="large">zum Thema</Text>

      <br />

      <Stack tokens={stackTokens} horizontalAlign="center">
        <Text variant="mega" styles={boldStyle}>
          Was ist Rust?
        </Text>
      </Stack>

      <br />

      <Text variant="large">von</Text>

      <br />

      <Stack tokens={stackTokens} horizontalAlign="center">
        <Text variant="large" styles={boldStyle}>
          Johannes Holzhauer
        </Text>
        <Text variant="large">Matrikelnummer: 26177</Text>
        <Text variant="large">Hochschule Merseburg</Text>
        <Text variant="large">11.07.2022</Text>
      </Stack>

      <Navigation next="/structure" />
    </Stack>
  );
};

export default Home;
