import { Outlet } from "react-router-dom";

import { Stack } from "@fluentui/react";

const Layout: React.FunctionComponent = () => {
  return (
    <Stack horizontalAlign="center" verticalFill>
      <Outlet />
    </Stack>
  );
};

export default Layout;
