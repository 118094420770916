import { Link as RouterLink } from "react-router-dom";
import { DefaultButton, Link } from "@fluentui/react";

interface LinkComponentProps {
  href: string;
  children: React.ReactNode;
  type?: "text" | "button";
  style?: React.CSSProperties;
}

const LinkComponentStyles = {
  textDecoration: "none",
};

const LinkComponent: React.FunctionComponent<LinkComponentProps> = ({
  href,
  children,
  type = "text",
  style = {},
}) => {
  return (
    <RouterLink to={href} style={{ ...LinkComponentStyles, ...style }}>
      {type === "text" ? (
        <Link>{children}</Link>
      ) : (
        <DefaultButton>{children}</DefaultButton>
      )}
    </RouterLink>
  );
};

export default LinkComponent;
