import { IStackTokens, IStackStyles } from "@fluentui/react";

const stackStyles: Partial<IStackStyles> = {
  root: {
    width: "960px",
    margin: "0 auto",
    textAlign: "center",
  },
};

const stackFullStyles: Partial<IStackStyles> = {
  root: {
    width: "100%",
    margin: "0 auto",
    textAlign: "left",
  },
};

const stackTokens: IStackTokens = { childrenGap: 15 };

export { stackFullStyles, stackStyles, stackTokens };
