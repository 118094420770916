import { Stack, ProgressIndicator, Text, Separator } from "@fluentui/react";
import CodeBlock from "../../components/CodeBlock";
import Navigation from "../../components/Navigation";
import { stackFullStyles, stackStyles, stackTokens } from "../../styles/stack";

const ownership = `struct Foo {
    x: i32,
}

fn mache_etwas(f: Foo) {
    println!("{}", f.x);
    // f wird hier fallen gelassen
}

fn main() {
    let foo = Foo { x: 42 };
    // foo wird an mache_etwas übergeben
    mache_etwas(foo);
    // foo nicht mehr benutzt werden
}`;

const returning = `struct Foo {
  x: i32,
}

fn mache_etwas() -> Foo {
  Foo { x: 42 }
  // Besitz der Ressource wird hier übergeben
}

fn main() {
  let foo = mache_etwas();
  // foo wir der Besitzer
  // foo wird hier fallen gelassen
}`;

const Structs: React.FunctionComponent = () => {
  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={0.33} />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Übertragung des Besitzes</Text>
      </Stack>

      <Text variant="large">
        Wenn ein Eigentümer als Argument an eine Funktion übergeben wird, wird
        die Eigentümerschaft auf den Funktionsparameter übertragen.
      </Text>

      <Text variant="large">
        Nach einer Verschiebung kann die Variable in der ursprünglichen Funktion
        nicht mehr verwendet werden.
      </Text>

      <Text variant="large">
        Bei einer Verschiebung wird der Stapelspeicher des Besitzerwertes in den
        Parameter-Stapelspeicher des Funktionsaufrufs kopiert.
      </Text>

      <CodeBlock code={ownership} />

      <Separator />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Rückgabe des Besitzes</Text>
      </Stack>

      <Text variant="large">
        Das Eigentum kann auch von einer Funktion zurückgegeben werden.
      </Text>

      <CodeBlock code={returning} />

      <Navigation prev="/ownership/owner" next="/ownership/borrow" />
    </Stack>
  );
};

export default Structs;
