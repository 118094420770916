import { Stack, MaskedTextField } from "@fluentui/react";
import React from "react";

const maskFormat: { [key: string]: RegExp } = {
  "*": /[a-zA-Z0-9_!"§$%&/()=?\{\[\]\}\\]/,
};
const stackTokens = { maxWidth: 300 };

const MaskedQuestion: React.FunctionComponent<{
  question: string;
  answer: string;
  mask: string;
  maskChar: string;
  errorMessage: string;
  setTestCompleted: (testCompleted: boolean) => void;
}> = ({ question, answer, mask, maskChar, errorMessage, setTestCompleted }) => {
  const [isValid, setIsValid] = React.useState(true);

  const onChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => {
    const value = newValue?.replaceAll(maskChar, "");

    if (value === answer) setTestCompleted(true);

    if (value?.length !== answer.length) return setIsValid(true);

    setIsValid(value === answer);
  };

  return (
    <Stack tokens={stackTokens} styles={{ root: { maxWidth: "100%" } }}>
      <MaskedTextField
        label={question}
        mask={mask}
        maskFormat={maskFormat}
        maskChar={maskChar}
        errorMessage={!isValid ? errorMessage : ""}
        required={true}
        onChange={onChange}
        styles={{
          wrapper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },
          field: { fontSize: "1.1rem" },
          fieldGroup: { maxWidth: "300px" },
          errorMessage: { fontSize: "1.2rem" },
          subComponentStyles: {
            label: { root: { fontSize: "1.25rem" } },
          },
        }}
      />
    </Stack>
  );
};

export default MaskedQuestion;
