import { Stack, ProgressIndicator, Text, Separator } from "@fluentui/react";
import CodeBlock from "../../components/CodeBlock";
import Navigation from "../../components/Navigation";
import { stackFullStyles, stackStyles, stackTokens } from "../../styles/stack";

const types = `struct Product {
    name: String;
    price: f32;
    description: String;
}`;

const methods = `fn main() {
  // Erzeugen eines Strings mithilfe einer statischen Methode
  let s = String::from("Hello world!");
  // Benutzen einer Methode der String Instanz
  println!("{} is {} characters long.", s, s.len());
}`;

const Structs: React.FunctionComponent = () => {
  return (
    <Stack styles={stackFullStyles} className="space-y-1">
      <ProgressIndicator percentComplete={0.0} />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Strukturen</Text>
      </Stack>

      <Text variant="large">
        Ein <code className="inline">struct</code> ist eine Ansammlung von
        Feldern.
      </Text>

      <Text variant="large">
        Ein Feld ist ein Wert, der mit einer Datenstruktur assoziiert wird. Dies
        kann ein primitiver Datentyp oder ein weiters{" "}
        <code className="inline">struct</code> sein.
      </Text>

      <Text variant="large">
        Die Difinition eines structs ist wie ein Entwurf für den Compiler, der
        ihm besagt, welche Felder im Speicher zueinander gehören.
      </Text>

      <CodeBlock code={types} />

      <Separator />

      <Stack horizontalAlign="center" styles={stackStyles} tokens={stackTokens}>
        <Text variant="xxLarge">Methoden</Text>
      </Stack>

      <Text variant="large">
        Einen feinen Unterschied zu Funktionen bilden Methoden. Methoden sind
        Erweiterungen zu Datentypen, bzw. Funktionen die auf Datentypen
        anzuwenden sind.
      </Text>

      <ul>
        <li>
          <Text variant="large">
            <code className="inline">statische Methoden</code> - Methoden, die
            zum Datentyp selber assoziiert sind. Werden mit dem{" "}
            <code className="inline">::</code> Operator aufgerufen.
          </Text>
        </li>
        <li>
          <Text variant="large">
            <code className="inline">instanz Methoden</code> (instance methods)
            - Methoden, die bei einer Instanz (oder Objekt) mit dem
            <code className="inline">.</code> Operator aufgerufen werden können.
          </Text>
        </li>
      </ul>

      <CodeBlock code={methods} />

      <Navigation prev="/flow/test" next="/structs/memory" />
    </Stack>
  );
};

export default Structs;
